* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.popup {
  opacity: 0;
  display: none;
}

.active-popup {
  display: flex;
  transform: translateX(0);
  opacity: 1;
}
.popupDefault {
  display: flex;
  transform: translateX(0);
  opacity: 1;
}

.active-popupDefaultHidden {
  opacity: 0;
  display: none;
}
